<template>
  <div id="login" class="page">
    <main v-if="siteLabel" class="container casino">
      <p class="casino-content" v-html="$t('Login.CasinoLoginHtml')" />
    </main>
    <main v-else class="container">
      <video
        class="bg-video"
        src="/video/login_video_football_0603a.mp4"
        muted
        autoplay
        loop
        playsinline
        webkit-playsinline
      />
      <div class="bg-video-mask" />
      <section class="login-content">
        <div class="login-card">
          <img class="login-logo promotion" :src="siteLoginLogo" />
          <form class="login-form">
            <div class="input-line">
              <div class="input-group">
                <img class="input-icon" src="@/assets/img/common/account.png" />
                <input
                  type="text"
                  class="text-input"
                  v-model="account"
                  autocomplete="off"
                  autofocus
                  :placeholder="$t('Login.Account')"
                  @keydown.enter="tryLogin"
                />
              </div>
              <div class="input-group save">
                <input
                  id="login-account-save"
                  type="checkbox"
                  class="save"
                  v-model="isAccountSave"
                  tabindex="-1"
                />
                <label for="login-account-save">
                  {{ $t('Login.Save') }}
                </label>
              </div>
            </div>
            <div class="input-line">
              <div class="input-group">
                <img class="input-icon" src="@/assets/img/common/password.png" />
                <input
                  class="text-input"
                  autocomplete="off"
                  v-model="password"
                  :type="isPasswordShow ? 'text' : 'password'"
                  :placeholder="$t('Login.Password')"
                  @keydown.enter="tryLogin"
                />
                <div
                  class="password-eye"
                  :class="{ open: isPasswordShow }"
                  @click="togglePasswordShow"
                />
              </div>
              <div class="input-group save">
                <input
                  id="login-password-save"
                  type="checkbox"
                  class="save"
                  tabindex="-1"
                  v-model="isPasswordSave"
                />
                <label for="login-password-save">
                  {{ $t('Login.Save') }}
                </label>
              </div>
            </div>
            <button type="button" class="login-submit" @click="tryLogin">
              {{ $t('Login.MemberLogin') }}
            </button>
          </form>
        </div>
        <div class="mode">
          <div v-if="MOBILE_APP" class="pc-mode" @click="gotoPcHost">
            <img class="pc-icon" src="@/assets/img/common/display-solid.svg" />
            {{ $t('Login.SwitchToPCVersion') }}
          </div>
          <div v-else class="mobile-mode" @click="gotoMobileHost">
            <img class="mobile-icon" src="@/assets/img/common/mobile.svg" />
            {{ $t('Login.SwitchToMobileVersion') }}
          </div>
          <el-select v-model="lang" @change="setNewLang" class="langs" size="mini">
            <el-option
              v-for="item in langOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="version" v-text="versionText" />
      </section>
    </main>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import { gameRoute, passwordRoute } from '@/router';
  import { APP_VERSION, MOBILE_APP } from '@/config';
  import { LOGIN_ACCOUNT_SAVE, LOGIN_PASSWORD_SAVE } from '@/config/storage';
  import { gotoPcHost, gotoMobileHost, gotoOldHost } from '@/config/host';

  export default {
    data() {
      return {
        account: '',
        password: '',
        isAccountSave: false,
        isPasswordSave: false,
        isPasswordShow: false,
        MOBILE_APP,
        APP_VERSION,
        promotionLogo: '/promotion/login.png',
        lang: 'tw',
        langOptions: [
          {
            value: 'tw',
            label: '繁體中文',
          },
          {
            value: 'cn',
            label: '简体中文',
          },
          {
            value: 'en',
            label: 'English',
          },
          {
            value: 'vi',
            label: 'Tiếng Việt',
          },
        ],
      };
    },
    computed: {
      ...mapState(['siteLabel']),
      ...mapGetters(['siteLoginLogo', 'siteApiVersion', 'userPasswordExpiredMessage']),
      versionText() {
        if (this.siteApiVersion) return `${this.siteApiVersion} - ${APP_VERSION}`;
        return APP_VERSION;
      },
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading']),
      ...mapActions(['login']),
      gotoPcHost,
      gotoMobileHost,
      gotoOldHost,
      tryLogin() {
        const { account, password } = this;
        if (!account || !password) return;

        this.showLoading();
        this.login({
          account,
          password,
        })
          .then((data) => {
            if (data.pwExpire) {
              this.$router.replace(passwordRoute).then(() => {
                this.hideLoading();
                this.$message({
                  type: 'warning',
                  message: this.userPasswordExpiredMessage,
                  duration: 5000,
                });
              });
            } else {
              if (this.isAccountSave) {
                // 對帳號加密
                const data = `aq9zls${this.account}1bva4`;
                localStorage.setItem(LOGIN_ACCOUNT_SAVE, data);
              } else {
                localStorage.removeItem(LOGIN_ACCOUNT_SAVE);
              }
              if (this.isPasswordSave) {
                // 對密碼加密
                const data = `ac5bc${this.password}1r2d34`;
                localStorage.setItem(LOGIN_PASSWORD_SAVE, data);
              } else {
                localStorage.removeItem(LOGIN_PASSWORD_SAVE);
              }
              if (this.lang !== this.$store.state.Setting.UserSetting.i18nLocale) {
                this.$store.dispatch('Setting/save').then(() => {
                  this.$store.dispatch('initSite').then(() => {
                    this.$store.dispatch('Game/updateGameTypeList').then(() => {
                      if (!data.ActionID === 1) {
                        this.$router.replace({ name: 'champions' });
                      } else {
                        this.$router.replace(gameRoute);
                      }
                    });
                  });
                });
              } else {
                if (!data.ActionID === 1) {
                  this.$router.replace({ name: 'champions' });
                } else {
                  this.$router.replace(gameRoute);
                }
              }
            }
          })
          .catch((error) => {
            console.error(error);
            this.hideLoading();
          });
      },
      toggleAccountSave() {
        this.isAccountSave = !this.isAccountSave;
      },
      togglePasswordSave() {
        this.isPasswordSave = !this.isPasswordSave;
      },
      togglePasswordShow() {
        this.isPasswordShow = !this.isPasswordShow;
      },
      setNewLang() {
        const { lang } = this;
        this.$store.commit('setI18nLocale', lang);
      },
    },
    created() {
      const accountSave = localStorage.getItem(LOGIN_ACCOUNT_SAVE);
      const passwordSave = localStorage.getItem(LOGIN_PASSWORD_SAVE);

      this.isAccountSave = !!accountSave;
      this.isPasswordSave = !!passwordSave;

      if (this.isAccountSave) {
        try {
          this.account = accountSave.substring(6, accountSave.length - 5);
        } catch (error) {
          console.error(error);
          localStorage.removeItem(LOGIN_ACCOUNT_SAVE);
          this.account = '';
        }
      }

      if (this.isPasswordSave) {
        try {
          this.password = passwordSave.substring(5, passwordSave.length - 6);
        } catch (error) {
          console.error(error);
          localStorage.removeItem(LOGIN_PASSWORD_SAVE);
          this.password = '';
        }
      }
    },
    mounted() {
      this.lang = this.$store.state.i18nLocale;
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    &.casino {
      background-image: url('~@/assets/img/common/nologin.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .casino-content {
    position: absolute;
    left: 8%;
    top: 32%;
    color: #eee;
    font-size: 32px;
  }

  video.bg-video {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 20%;
    z-index: 1;
    transform: translate(-50%, -20%);
  }

  .bg-video-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .login-content {
    position: absolute;
    width: 100%;
    min-height: 100%;
    z-index: 10;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
  }

  img.promotion-logo {
    max-width: 100%;
    max-height: 140px;
  }

  img.login-logo {
    max-width: 80%;
    max-height: 160px;

    &.promotion {
      max-height: 120px;
    }
  }

  .login-card {
    position: relative;
    width: 90%;
    max-width: 400px;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .login-form {
    position: relative;
    width: 100%;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .input-line {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
  }

  .input-group {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;

    img.input-icon {
      position: absolute;
      height: 30px;
      top: 3px;
      left: 3px;
    }

    input.text-input {
      width: 100%;
      height: 36px;
      padding: 0 40px;
      border: 1px solid #444;
      border-radius: 18px;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      line-height: 36px;
      font-size: 18px;
      text-align: center;

      &::placeholder {
        color: #ccc;
      }

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.8);
        border: 1px solid #222 !important;
        color: #fff;
      }
    }

    .password-eye {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 10px;
      top: 50%;
      background-image: url('~@/assets/img/common/eye-solid.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: translateY(-50%);
      cursor: pointer;

      &::after {
        position: absolute;
        width: 24px;
        height: 1px;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%) rotate(45deg);
        background: #ccc;
        content: '';
      }

      &.open {
        &:after {
          display: none;
        }
      }
    }

    &.save {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-end;
      flex-grow: 0;
      flex-shrink: 0;

      input.save {
        width: 16px;
        accent-color: rgba(56, 166, 136, 1);
        cursor: pointer;
      }

      label {
        font-size: 14px;
        color: #ccc;

        &:hover {
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .login-submit {
    width: 86%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 16px;
    background-color: rgba(56, 166, 136, 0.6);
    color: #fff;
    font-size: 18px;
    text-align: center;
    cursor: pointer;

    &:active,
    &:hover {
      background-color: rgba(56, 166, 136, 1);
    }
  }

  .mode {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 4px;
  }

  .pc-mode,
  .mobile-mode,
  .old-mode {
    color: #ccc;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  img.pc-icon,
  img.mobile-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  .version {
    color: #aaa;
  }
  .langs {
    font-size: 13px;
    width: 120px;
  }
</style>
